<template>
  <div>
    <div id="interactive-admin-wrapper">
      <div v-if="canManageTed">
        <a
            class="item item-icon-left"
            style="display:block;margin: 0 auto;"
            href="#/app/interactive_voting/{{channel}}"
        >
          <i class="icon ion-wrench"></i>
          <span translate="MANAGE_VOTING"></span>
        </a>
      </div>
      <div v-if="canManageQandA">
        <a
            class="item item-icon-left"
            style="display:block;margin: 0 auto;"
            href="#/app/interactive_qanda/{{channel}}"
        >
          <i class="icon ion-wrench"></i>
          <span translate="MANAGE_QANDA"></span>
        </a>
      </div>
      <div v-if="canManageCloud">
        <a
            class="item item-icon-left"
            style="display:block;margin: 0 auto;"
            href="#/app/interactive_cloud/{{channel}}"
        >
          <i class="icon ion-wrench"></i>
          <span>Cloud</span>
        </a>
      </div>
      <div v-if="showTed || result" id="livevote" style="padding:10px">
        <strong
            class="padding item item-text-wrap"
            v-if="(currentQuestion && currentQuestion.question) || (questionResult && !compareVoting.question)"
        >
          <span
              class="question-identifier"
              :style="{'background-color' : (((currentQuestion && currentQuestion.controverseId && currentQuestion.controverseId != 'true')|| (questionResult && questionResult.controverseId && questionResult.controverseId != 'true')) ? '#f0ad4e' : '#5bc0de')}"
          ></span>
          {{ currentQuestion ? currentQuestion.question : '' || questionResult ? questionResult.question : '' }}
        </strong>
        <strong
            class="padding item item-text-wrap"
            v-if="questionResult && compareVoting && compareVoting.question && compareVoting.id == questionResult.controverseId"
        >
          <span class="question-identifier" style="background-color : #5bc0de"></span>
          {{ questionResult.question }}
          <br/>
          <span class="question-identifier" style="background-color : #f0ad4e;"></span>
          Controversial question
        </strong>
        <div
            class="item-voting-response"
            v-if="alreadyVoted != -1"
            :class="(alreadyVoted == 1 ? 'item-icon-left item-danger' : '') || (alreadyVoted == 0 ? 'item-icon-left item-success' : '')"
        >
          <i v-if="alreadyVoted == 1" class="icon ion-close">
            <span>Sie haben bereits abgestimmt</span>
          </i>
          <i v-if="alreadyVoted == 0" class="icon ion-checkmark">
            <span>Vielen Dank für Ihre Abstimmung</span>
          </i>
        </div>
        <div class="padding" v-if="!result && currentQuestion">
          <ion-button
              color="light"
              expand="block"
              class="button button-block button-stable"
              v-for="answer in currentQuestion.Answers"
              :key="answer"
              @click="sendVote(currentQuestion.id,answer)" style="text-transform: none;white-space:normal; min-height:40px; height:auto;font-size:0.9rem"
          ><div style="white-space:normal; min-height:40px; max-height:auto;font-size:0.9rem; padding:5px">{{ answer.text }}</div>
          </ion-button>
        </div>
        <div class="padding" v-if="result">
          <div class="item item-voting" v-for="answer in questionResult.Answers" :key="answer">
            <span style="font-size:0.9rem">{{ answer.text }}</span>
            <div
                class="row"
                v-if="compareVoting && compareVoting.question && compareVoting.id == questionResult.controverseId "
            >
              <div style="margin:3px 12px 0 12px;padding:0; width:95%; background:lightgrey">
                <ion-progress-bar
                    style="height:10px"
                    color="primary" :style="{width:((compareVoting.Answers[$index].votes) / (compareVoting.totalVotes) * 100 || 0) + '%'}"
                    :value="((compareVoting.Answers[$index].votes) / (compareVoting.totalVotes) * 100 || 0)"
                ></ion-progress-bar>
              </div>
              <small>{{ ((compareVoting.Answers[$index].votes) / (compareVoting.totalVotes) * 100 || 0) }}%</small>
            </div>
            <br/>
            <div class="row">
              <div style="margin:3px 12px 0 12px;padding:0; width:95%; background:lightgrey">
                <ion-progress-bar
                    style="height:10px"
                    color="primary" :style="{width:((answer.votes) / (questionResult.totalVotes) * 100 || 0) + '%'}"
                    :value="((answer.votes) / (questionResult.totalVotes) * 100 || 0)"
                ></ion-progress-bar>
              </div>

              <small>{{ ((answer.votes) / (questionResult.totalVotes) * 100 || 0) }}%</small>
            </div>
            <br/>
          </div>
          <div class="padding" style="margin-bottom: 5px; font-size:0.9rem">
            <span>Anzahl Votes: </span>
            <span>{{ questionResult.totalVotes }}</span>
          </div>
        </div>
      </div>
      <div v-if="showQandA">
        <div class="list">

          <strong style="margin-left:15px">Meine Frage</strong>
          <div style="margin:0 15px">
            <ion-toolbar style="height:55px; margin:5px 0">
              <ion-row>
                <ion-col size="10">
                  <ion-textarea placeholder="Type your question" :value="qanda.text" style="--padding-top:0; font-size:0.9rem"
                                @ionInput="qanda.text = $event.target.value;"
                  ></ion-textarea>
                </ion-col>
                <ion-col size="2" style="height:50px;display:flex; justify-content:center; align-content:center;justify-items:center;align-items:center">
                  <ion-button class="send-button"  @click="sendQuestion(qanda)" style="background:none !important; font-size:0.9rem"
                              :class="{'button-disabled' : qanda.text.length == 0}" fill="clear">
                    <ion-icon slot="end" :name="'send-sharp'"></ion-icon>
                    Senden
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-toolbar>

          </div>
          <!--div class="item-input-inset">
            <label class="item-input-wrapper">
              <i class="icon ion-edit placeholder-icon"></i>
              <textarea
                rows="2"
                type="text"
                placeholder="Enter question"
                v-model="qanda.text"
                style="background-color: #EEEEEE;width: 100%;padding-left: 10px; border-left:1px solid #dddddd;padding-top:5px;margin-left: 10px;margin-right: -10px; resize: none;"
              ></textarea>
            </label>
          </div-->

          <ion-item v-if="user" style="border:0px !important">
            <ion-label style="font-size:0.9rem"><span>Senden als</span>
              {{ user.firstName }} {{ user.lastName }}
            </ion-label>
            <ion-checkbox slot="end" @update:modelValue="qanda.sendUser = $event" :modelValue="qanda.sendUser">
            </ion-checkbox>
          </ion-item>
          <!--a
            class="button button-block button-small button-positive"
            style="width:94%; margin: 10px auto 10px auto;"
            :style="{'margin-top': user ? '10px': '0px'}"
            @click="sendQuestion(qanda)"
            :class="{'disabled' : qanda.text.length == 0}"
          >
            <span translate="QANDA_SEND"></span>
          </a-->
        </div>
        <div class="item item-divider" v-if="allQuestions.length > 0 && !privateQanda">
          <br>
          <strong style="margin-left:20px">Fragen vom Publikum</strong>
        </div>
        <div v-for="question in allQuestions" :key="question.id">
          <ion-item v-if="!privateQandA || (privateQandA && !question.private)">
            <ion-label class="ion-text-wrap">
              <h2>
                <div style="font-size:1rem">“{{ question.text }}“</div>
              </h2>
              <ion-text color="medium">
                <small style="font-size:0.8rem">{{ question.userName || 'Anonymous' }}, {{ moment(question.time).format('dddd HH:mm') }}</small>
              </ion-text>
            </ion-label>
            <ion-note slot="end" @click="upvote(question)">
              <ion-icon
                  :name="!question.upvoted ? 'thumbs-up-outline' :'thumbs-up'"
                  style="font-size:1.4rem"
              ></ion-icon>
              {{ question.votes }}
            </ion-note>
          </ion-item>
          <!--div
            v-if="!privateQanda || (privateQanda && !question.private)"
            class="item item-avatar item-icon-right">
            <img
              :src="(question.picture) ? 'https://file-server.documedias.com/file/' + (question.picture) + '/resize/40/40': 'img/avatar.png'"
            />
            <div class="question-vote-indicator">
              <span class="question-vote-indicator-icon" :class="{'ion-ios-heart-outline' : true}"></span>
              <span class="question-vote-indicator-number">{{question.votes}}</span>
            </div>
            <h2 style="font-style:italic; color: dimgrey; white-space: normal">“{{question.text}}“</h2>
            <p>{{question.userName || 'Anonymous'}}</p>
            <small>{{question.time}}</small>
            <div
              class="icon"
              :class="{'ion-ios-heart-outline' : !question.upvoted, 'ion-ios-heart' : question.upvoted}"
              @click="upvote(question)"
            ></div>
          </div-->
        </div>
      </div>
      <!--div v-if="showCloud" class="item-cloud-response">
        <div class="list">
          <div class="item item-divider" style="background: #fff; border:none">
            <span>Word Cloud</span>
          </div>
          <div
            class="item-response"
            v-show="alreadySentWord != -1"
            :class="(alreadySentWord == 1 ? 'item-icon-left item-danger' : '') || (alreadySentWord == 0 ? 'item-icon-left item-success' : '')"
          >
            <i v-show="alreadySentWord == 1" class="icon ion-close">
              <span translate="VOTEERR"></span>
            </i>
            <i v-show="alreadySentWord == 0" class="icon ion-checkmark">
              <span translate="VOTETHX"></span>
            </i>
          </div>
          <div class="item-input-inset">
            <label class="item-input-wrapper">
              <i class="icon ion-edit placeholder-icon"></i>
              <input
                type="text"
                placeholder="Insert your word"
                v-model="word.text"
                style="background-color: #EEEEEE;width: 100%;padding-left: 10px; border-left:1px solid #dddddd;padding-top:5px;margin-left: 10px;margin-right: -10px; resize: none;"
              />
            </label>
          </div>
          <a
            class="button button-block button-small button-positive"
            style="width:94%; margin: 10px auto 10px auto;"
            :style="{'margin-top': user ? '10px': '0px'}"
            @click="sendWord(word.text)"
            :class="{'disabled' : word.text.length == 0}"
          >
            <span>Send word</span>
          </a>
        </div>
      </div-->
    </div>
  </div>
</template>

<script>
import {
  IonProgressBar,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonIcon,
  IonCheckbox
} from "@ionic/vue";
import {defineComponent} from "vue";
import moment from "moment";

import {addIcons} from "ionicons";
import {thumbsUpOutline, thumbsUp,sendSharp} from "ionicons/icons";
import {mapGetters, mapState} from "vuex";

addIcons({"thumbs-up-outline": thumbsUpOutline, "thumbs-up": thumbsUp , "send-sharp": sendSharp});
const config = require('@/config');
export default defineComponent({
  data() {
    return {
      conf: config.conference,
      roomId: null,
      showQandA: false,
      showTed: false,
      showCloud: false,
      qanda: {text: "", sendUser: true},
      allQuestions: [],
      result: false,
      transferStatus: "success",
      currentQuestion: "",
      alreadyVoted: -1,
      alreadySentWord: -1,
      privateQandA: false,
      questionResult: "",
      activeCloud: "",
      compareVoting: "",
      hideIds: [],
      initialized: false
    };
  },
  components: {
    IonProgressBar,
    IonItem,
    IonLabel,
    IonTextarea,
    IonButton,
    IonIcon,
    IonCheckbox
  },
  props: ["sessionId"],
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sessions', ['joinedChannel']),
    channel: function () {
      return this.conf + "_" + this.sessionId;
    }
  },
  methods: {
    joinChannel() {
      console.log('userview_joinChannel');
      console.log('userview_store_' + this.joinedChannel);
      if (this.channel === this.joinedChannel) {
        console.log('userview_joinChannel_abort');
        return false;
      }
      if (this.joinedChannel !== null) {
        console.log('userview_leaveChannel_' + this.joinedChannel);
        this.$socketInt.emit("leave", this.joinedChannel);
      }
      console.log('userview_join_' + this.channel);
      this.$socketInt.emit("join", this.channel);
      this.$store.commit('sessions/setJoinedChannel', {joinedChannel: this.channel});
    },
    getQandA() {
      this.$socketInt.emit("getQandA", {
        congressId: this.conf + "",
        sessionId: this.sessionId
      });
    },
    getCurrentVotingOrResult() {
      this.$socketInt.emit("getCurrentVotingOrResult", {
        congressId: this.conf,
        roomId: this.roomId,
        sessionId: this.sessionId
      });
    },
    getCurrentCloud() {
      this.$socketInt.emit("getCurrentCloud", {
        congressId: this.conf,
        sessionId: this.sessionId
      });
    },
    upvote(question, sessionId, user) {
      if (!question.upvoted) {
        if (this.hideIds.indexOf(question.id) === -1) {
          this.hideIds.push(question.id);
        }
        this.$socketInt.emit("upVote", {
          questionId: question.id,
          congressId: this.conf + "",
          sessionId: this.sessionId,
          user: user
        });
        if (this.allQuestions) {
          this.allQuestions.map(question => {
            if (this.hideIds.indexOf(question.id) >= 0) {
              question.upvoted = true;
            }
          });
        }
      }
    },
    sendQuestion(qanda) {
      let question = {
        qandaId: qanda.id,
        congressId: this.conf + "",
        sessionId: this.sessionId,
        text: qanda.text,
        userName: qanda.sendUser && this.user ? this.user.firstName + ' ' + this.user.lastName : 'Anonymous',
        picture: null, //qanda.sendUser && $rootScope.user ? $rootScope.user.picture : null,
        time: new Date()
      };
      this.$socketInt.emit("addQuestions", question);
      this.qanda.text = "";
    },

    sendVote(votingId, answer) {
      console.log("sendVote", votingId, answer.id);
      this.$socketInt.emit("vote", {
        congressId: this.conf + "",
        sessionId: this.sessionId,
        votingId: votingId,
        answerId: answer.id,
        personId:  this.user ? this.user.id : 123
      });
    },

    sortVotes(votes) {
      return votes.sort(function (vote1, vote2) {
        if (vote1.votes > vote2.votes) return -1;
        if (vote1.votes < vote2.votes) return 1;

        if (vote1.time > vote2.time) return -1;
        if (vote1.time < vote2.time) return 1;
      });
    }
  },

  unmounted() {
    // this.$socketInt.emit("leave", this.channel);
  },
  updated() {
    if(this.joinedChannel !== this.channel) {
      this.joinChannel();
      this.getQandA();
      this.getCurrentVotingOrResult();
      this.getCurrentCloud();
    }
  },
  created() {
    console.log('userview_created()');
    this.moment = moment;
    this.joinChannel();
    this.getQandA();
    this.getCurrentVotingOrResult();
    this.getCurrentCloud();

    this.$socketInt.on("qanda", res => {
      if (res && res.sessionId == this.sessionId) {
        this.showQandA = res.active;
        this.qanda.id = res.id;
        this.privateQandA = res.private;
        this.allQuestions = this.sortVotes(res.UserQs);
        if (this.allQuestions) {
          this.allQuestions.map(question => {
            if (this.hideIds.indexOf(question.id) >= 0) {
              question.upvoted = true;
            }
          });
        }
      } else {
        console.log('ELSE')
        this.showQandA = false;
      }
    });


    //finished
    this.$socketInt.on("voting", res => {
      if (
          res &&
          res.voting &&
          res.voting.sessionId == this.sessionId &&
          res.voting.question &&
          res.voting.active
      ) {
        console.log("setVoting");
        this.showTed = true;
        this.currentQuestion = res.voting;
        this.result = false;
        this.questionResult = "";
        this.alreadyVoted = -1;
        //console.log(this.currentQuestion);
      } else {
        //console.log("endVoting");
        this.currentQuestion = "";
        this.alreadyVoted = -1;
      }
    });

    this.$socketInt.on("cloud", res => {
      if (
          res &&
          res.cloud &&
          res.cloud.sessionId == this.sessionId &&
          res.cloud.title &&
          res.cloud.active
      ) {
        this.showCloud = true;
        this.activeCloud = res.cloud;
      } else {
        this.showCloud = false;
      }
    });

    //finished
    this.$socketInt.on("getPublicResults", res => {
      if (
          res &&
          res.voting &&
          res.voting.sessionId == this.sessionId &&
          res.voting.resultApp
      ) {
        //console.log("showResults", res.voting);
        this.currentQuestion = "";
        this.questionResult = res.voting;
        this.result = true;
        //console.log(this.questionResult);
      } else {
        //console.log("hideResults");
        this.questionResult = "";
        this.result = false;
      }
    });

    this.$socketInt.on("compare", res => {
      if (res && res.comparison && res.comparison.sessionId == this.sessionId) {
        this.compareVoting = res.comparison;
      } else {
        this.compareVoting = "";
      }
    });

    this.$socketInt.on("voted", res => {
      console.log("already voted", res);
      this.alreadyVoted = res;
    });

    this.$socketInt.on("sentWord", res => {
      this.alreadySentWord = res;
    });

    this.$socketInt.on("upVoted", res => {
      if (res && this.hideIds.indexOf(res) === -1) {
        this.hideIds.push(res);
      }
    });
  }
});
</script>
